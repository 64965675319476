import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'
import Header from '../components/Header/Header'


const ImpressumPage = ({ data }) => {
  const { titleStage, slug, titleCol1, titleCol2 } = data.contentfulSubsites
  const contentCol1 = data.contentfulSubsites.childContentfulSubsitesContentCol1RichTextNode.json
  const contentCol2 = data.contentfulSubsites.childContentfulSubsitesContentCol2RichTextNode.json

  let options = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => <h2 className='mb-8 mt-4'>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3 className='mb-6 mt-3'>{children}</h3>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p className='mb-6'>{children}</p>
    },
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
  }

  return (
    <Layout>
      <SEO title={titleStage}></SEO>
      <div className='flex w-full p-6 justify-center'>
      <div className='flex max-w-6xl flex-wrap w-full xs:my-16 lg:my-24 justify-center'>
      <div className='flex w-full flex-wrap text-left pr-6 mb-16'>
          <div className='flex w-full'>
            <h1 className='mb-8'>{titleStage}</h1>
          </div>
        </div>
        <div className='flex w-full md:w-1/2 flex-wrap text-left pr-6 mb-16'>
          <div className='flex w-full'>
          <div className='mb-8 font-bold'>{titleCol1}</div>
          </div>
          <div className='w-full'>
            {documentToReactComponents(contentCol1, options)}
          </div>
        </div>
        <div className='w-full md:w-1/2 md:pl-6 text-left'>
          <div className='w-full'>
            <div className='mb-8 font-bold'>{titleCol2}</div>
          </div>
          <div className='w-full'>
            {documentToReactComponents(contentCol2, options)}
          </div>
        </div>
      </div>
      </div>
    </Layout>
  )
}

export default ImpressumPage

export const Subsites =  graphql`
query {
  contentfulSubsites(slug: {eq: "impressum"}) {
    titleStage
    slug
    titleCol1
    titleCol2
    childContentfulSubsitesContentCol1RichTextNode {
      json
    }
    childContentfulSubsitesContentCol2RichTextNode {
      json
    }
  }
}
`
